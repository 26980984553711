<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="2">
          <v-text-field
            :error-messages="ordinalNumeralErrors"
            :label="$t('ordinalNumeral')"
            @blur="$v.item.ordinalNumeral.$touch()"
            @input="$v.item.ordinalNumeral.$touch()"
            v-model.number="item.ordinalNumeral"
            type="number"
            min="1"
            outlined
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            :error-messages="nameErrors"
            :label="$t('positionName')"
            @blur="$v.item.name.$touch()"
            @input="$v.item.name.$touch()"
            v-model="item.name"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :error-messages="quantityErrors"
            :label="$t('quantity')"
            @blur="$v.item.quantity.$touch()"
            @input="$v.item.quantity.$touch()"
            v-model.number="item.quantity"
            outlined
            type="number"
            min="1"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            :error-messages="quantityUnitErrors"
            :label="$t('quantityUnit')"
            @blur="$v.item.quantityUnit.$touch()"
            @input="$v.item.quantityUnit.$touch()"
            required
            :items="quantityUnitValues"
            v-model="item.quantityUnit"
            outlined
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            :error-messages="unitPriceErrors"
            :label="$t('unitPrice')"
            @blur="$v.item.unitPrice.$touch()"
            @input="$v.item.unitPrice.$touch()"
            v-model.number="item.unitPrice"
            outlined
            prefix="€"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h4>{{ $t('description') }}</h4>
          <TextTemplateSelector
            @textTemplateSelected="templateSelected('description', ...arguments)"
          />
          <InputEditor
            v-model="item.description"
            :error-messages="descriptionErrors"
            :required="true"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :error-messages="discountErrors"
            :label="$t('discount')"
            :hint="$t('discountHint')"
            @blur="$v.item.discount.$touch()"
            @input="$v.item.discount.$touch()"
            v-model.number="item.discount"
            outlined
            type="number"
            suffix="%"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {validationMixin} from 'vuelidate';
import has from 'lodash/has';
import {required} from 'vuelidate/lib/validators';
import InputEditor from "@/components/InputEditor";
import TextTemplateSelector from "@/components/textTemplate/Selector";

export default {
  name: 'PositionForm',
  mixins: [validationMixin],
  components: {
    InputEditor,
    TextTemplateSelector
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    loading: {
      type: Boolean,
      required: true
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },
    handleSubmit: {
      type: Function,
      required: false
    },
    handleReset: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      ordinalNumeral: 0,
      name: '',
      description: null,
      quantity: 1,
      quantityUnit: null,
      unitPrice: null,
      discount: null,
      quantityUnitValues: [
        {text: this.$t('quantityUnit.piece'), value: 'Stk.'},
        {text: this.$t('quantityUnit.days'), value: 'Tag(e)'},
      ],
    };
  },
  computed: {
    item() {
      return this.initialValues || this.values;
    },
    ordinalNumeralErrors() {
      const errors = [];

      if (!this.$v.item.ordinalNumeral.$dirty) {
        return errors;
      }

      has(this.violations, 'ordinalNumeral') && errors.push(this.violations.ordinalNumeral);

      !this.$v.item.ordinalNumeral.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    nameErrors() {
      const errors = [];

      if (!this.$v.item.name.$dirty) {
        return errors;
      }

      has(this.violations, 'name') && errors.push(this.violations.name);

      !this.$v.item.name.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.item.description.$dirty) {
        return errors;
      }
      has(this.violations, 'description') && errors.push(this.violations.description);

      return errors;
    },
    quantityErrors() {
      const errors = [];

      if (!this.$v.item.quantity.$dirty) {
        return errors;
      }
      has(this.violations, 'quantity') && errors.push(this.violations.quantity);
      !this.$v.item.quantity.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    quantityUnitErrors() {
      const errors = [];

      if (!this.$v.item.quantityUnit.$dirty) {
        return errors;
      }
      has(this.violations, 'quantityUnit') && errors.push(this.violations.quantityUnit);
      !this.$v.item.quantityUnit.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    unitPriceErrors() {
      const errors = [];

      if (!this.$v.item.unitPrice.$dirty) {
        return errors;
      }
      has(this.violations, 'unitPrice') && errors.push(this.violations.unitPrice);
      !this.$v.item.unitPrice.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    discountErrors() {
      const errors = [];

      if (!this.$v.item.discount.$dirty) {
        return errors;
      }
      has(this.violations, 'discount') && errors.push(this.violations.discount);

      return errors;
    },
    violations() {
      return this.errors || {};
    }
  },
  methods: {
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
    templateSelected(target, textTemplate) {
      if (textTemplate && target) {
        this.$set(this.item, target, textTemplate);
      }
    },
  },
  validations: {
    item: {
      ordinalNumeral: {
        required
      },
      name: {
        required
      },
      description: {},
      quantity: {
        required
      },
      quantityUnit: {
        required
      },
      unitPrice: {
        required
      },
      discount: {},
    }
  },
};
</script>
