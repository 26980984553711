<template>
  <div>
    <v-data-table
      :headers="positionHeaders"
      :items="resolvedPositions"
      :single-expand="false"
      show-expand
      :disable-pagination="true"
      :hide-default-footer="true"
      sort-by="ordinalNumeral"
      item-key="@id"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('Positions') }}</v-toolbar-title>
          <v-spacer/>
          <v-btn color="primary" fab small @click="createOrEditHandler(null)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <PositionDialog
            v-model="showDialogForm"
            :position-item="formItem"
            :handle-created="onCreated"
            :handle-updated="onUpdated"
            :is-create-form="true"
            :show-handle="showDialogForm"
            @close="dialogControl(true)"
            :key="formItem.id"
          />
        </v-toolbar>
      </template>
      <template slot="item.discount" slot-scope="{ item }">
        {{ item.discount ? item.discount : 0 }}%
      </template>
      <template slot="item.unitPrice" slot-scope="{ item }">
        {{ item.unitPrice }} &euro;
      </template>
      <template slot="item.totalPrice" slot-scope="{ item }">
        {{ calculateTotalPrice(item) }} &euro;
      </template>
      <ActionCell
        :handle-edit="() => createOrEditHandler(props.item)"
        :handle-delete="() => deletePositionHandler(props.item)"
        slot="item.action"
        slot-scope="props"
      />
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div style="white-space: pre-line;" class="py-4" v-html="item.description"/>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import PositionDialog from "@/components/position/Dialog";
import remove from "lodash/remove";
import PositionMixin from "@/mixins/PositionMixin";
import ActionCell from "@/components/ActionCell";

export default {
  name: 'PositionListAndAdd',
  mixins: [PositionMixin],
  components: {
    ActionCell,
    PositionDialog,
  },
  props: {
    positions: {
      type: Array,
      default: () => []
    },
    defaultItemProps: {
      type: Object,
      required: true,
    },
    handleMessage: {
      type: Function,
      required: true
    },
    objectType: {
      type: String,
      required: true
    },
    updateParent: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      positionHeaders: [
        {
          text: 'Pos.',
          align: 'start',
          value: 'ordinalNumeral',
          sortable: false,
        },
        {text: 'Name', value: 'name', sortable: false,},
        {text: 'Menge', value: 'quantity', sortable: false,},
        {text: 'Einheit', value: 'quantityUnit', sortable: false,},
        {text: 'Einzelpreis', value: 'unitPrice', sortable: false,},
        {text: 'Rabatt', value: 'discount', sortable: false,},
        {text: 'Gesamtpreis', value: 'totalPrice', sortable: false,},
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
        },
        {text: '', value: 'data-table-expand'},
      ],
      positionsCreate: [],
      positionsExpanded: [],
      showDialogForm: false,
      formItem: {},
    };
  },
  computed: {
    resolvedPositions() {
      return this.resolvePositions(this.positions);
    }
  },
  methods: {
    calculateTotalPrice(item) {
      let unitPrice = parseFloat(item.unitPrice);
      if (item.discount && item.discount > 0) {
        unitPrice = unitPrice - (unitPrice * (item.discount / 100));
      }
      return item.quantity * unitPrice;
    },
    onCreated(item) {
      if (item[this.objectType] !== null) {
        this.positions.push(item['@id']);
        this.updateParent({'@id': this.formItem[this.objectType], 'positions': this.positions});
      }
    },
    onUpdated() {
    },
    createOrEditHandler(expenseItem) {
      this.formItem = expenseItem ?? this.defaultItemProps;
      if(expenseItem === null){
        this.formItem.ordinalNumeral = this.positions.length + 1;
        this.formItem.quantity = 1;
        this.formItem.quantityUnit = 'Stk.';
      }
      this.dialogControl();
    },
    dialogControl(close = false) {
      this.showDialogForm = !close;
    },
    deletePositionHandler(item) {
      let cleanedPositions = remove(this.positions, (uid) => {
        return !(uid === item['@id']);
      });
      this.$emit('updatePositions', cleanedPositions);
      this.deletePositionItem(item);
    },
  },
  watch: {
    deletedPositionItem() {
      this.handleMessage(`Position deleted.`);
    },
  },
}
</script>
