export default {
  methods: {
    elementForDialog(modelObject, dialogObject) {
      let currentItemKeys = Object.keys(dialogObject);
      if (currentItemKeys.length === 0) {
        return false;
      }
      let isElementForDialog = true;
      currentItemKeys.forEach(itemKey => {
        if (!dialogObject[itemKey] && !modelObject[itemKey]) {
          // there is issue that a "currentCustomerObject" from Form.vue appears, that let not validate form & show message etc.
          return;
        }
        if (typeof (dialogObject[itemKey]) === 'object' && typeof (modelObject[itemKey]) === 'object') {
          if (JSON.stringify(dialogObject[itemKey]) !== JSON.stringify(modelObject[itemKey])) {
            isElementForDialog = false;
          }
        } else if (dialogObject[itemKey] !== modelObject[itemKey]) {

          isElementForDialog = false;
          if ((typeof modelObject[itemKey]['@id'] !== 'undefined' && dialogObject[itemKey] === modelObject[itemKey]['@id'])) {
            isElementForDialog = true;
          }
        }
      });
      return isElementForDialog;
    }
  }
};
