<template>
  <v-autocomplete
    v-model="selectedCustomer"
    :items="availableCustomers"
    :loading="isCustomersLoading"
    :search-input.sync="customerApiSearch"
    v-on:input="onCustomerSelected"
    :label="label ?? $t('Customer')"
    no-filter
    hide-no-data
    outlined
    clearable
    :required="required"
  >
    <template v-slot:selection="data">
      <v-chip v-bind="data.attrs" :input-value="data.selected">
        {{ data.item.customerNumber }}:
        {{ getProfileItemBasics(data.item.profile, 'firstName') }}
        {{ getProfileItemBasics(data.item.profile, 'lastName') }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          {{ getProfileItemBasics(data.item.profile, 'firstName') }}
          {{ getProfileItemBasics(data.item.profile, 'lastName') }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.customerNumber }}
          <template v-if="getProfileItemBasics(data.item.profile, 'company')">
            | {{ getProfileItemBasics(data.item.profile, 'company') }}
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import ProfileMixin from '@/mixins/ProfileMixin';
import CustomerMixin from '@/mixins/CustomerMixin';
import { mapActions } from 'vuex';

export default {
  name: 'CustomerSearch',
  mixins: [ProfileMixin, CustomerMixin],
  props: {
    onSelected: {
      type: Function,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: () => null
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },

  data() {
    return {
      selectedCustomer: null,
      customerApiSearch: null,
      isCustomersLoading: false,
      controller: new AbortController(),
      customerSearchResults: []
    };
  },
  computed: {
    availableCustomers() {
      return this.customerSearchResults;
    },
  },
  methods: {
    ...mapActions('customer', {
      getCustomers: 'fetchAll'
    }),
    onCustomerSelected(customer){
      this.onSelected(customer);
    }

  },
  watch: {
    customerApiSearch(val) {
      if (val.length < 3) {
        // min chars for search is 3 chars
        return;
      }
      this.isCustomersLoading = true;
      this.controller.abort();
      this.controller = new AbortController();
      const signal = this.controller.signal;
      const params = {
        page: 1,
        itemsPerPage: 30,
        search: val,
        signal: signal
      };
      this.getCustomers(params)
        .then((result) => {
          this.customerSearchResults = result ?? [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isCustomersLoading = false;
        });
    }
  }
};
</script>
