import moment from 'moment';

const date = function (value) {
  if(value !== null){
    return moment(value).isValid();
  }
  return true;

};

export { date };
