import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import remove from "lodash/remove";

export default {
  data() {
    return {
      loadingPositions: [],
    };
  },
  computed: {
    ...mapFields("position", {
      positionIds: "allIds",
      positionIdsCached: "cacheAllIds",
      deletedPositionItem: 'deleted',
    }),
    ...mapGetters("position", {
      positionItems: 'list',
      findPosition: "find",
    }),
  },
  methods: {
    ...mapActions("position", {
      retrievePosition: "load",
      fetchAllPositions: 'fetchAll',
      deletePositionItem: 'del',
      updatePosition: 'update',
    }),
    resolvePosition(itemId) {
      if (!this.positionIdsCached.includes(itemId) && !this.loadingPositions.includes(itemId)) {
        this.loadingPositions.push(itemId);
        this.retrievePosition(decodeURIComponent(itemId));
      }
      return this.findPosition(itemId);
    },
    resolvePositions(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolvePosition(itemId);
      }).filter(e => e);
    },
    getPositionItem(positionIri, type) {
      if (positionIri !== null) {
        let position = this.resolvePosition(positionIri);
        if (position) {
          return position[type];
        }
      }
      return '';
    },
    removePositionRelated(item, type, relatedIri){
      item[type] = remove(item[type], function (n) {
        return !(n === relatedIri);
      });
    }
  }
};
